@import "../../assets/stylesheets/modules/variable";
@import "../../assets/stylesheets/modules/mixin";

/* Portrait tablets and small desktops */

@media (min-width: 768px) and (max-width: 991px) {
  //code goes here
  .filter-color-first-wrapper {
    left: 650px !important;
    z-index: 0;
  }
}

/* Landscape phones and portrait tablets */
@media (max-width: 767px) {
}

/* Portrait phones and smaller */
@media (max-width: 650px) {
  .new_join-form-wrapper {
    width: auto !important;
    background-color: transparent !important;
    // min-height: 470px;
    border: none !important;
    border-radius: 0 !important;
    padding: 0 18px 18px 18px !important;
  }
  .data-form-wrapper {
    margin-top: 59px !important;
  }
  .login-url-wrap {
    display: none !important;
  }
  .join-form-cta-section {
    display: none !important;
  }
  .mobile-login-url-wrap {
    display: block !important;
    display: flex !important;
    justify-content: center !important;
  }
  .mobile-join-form-cta-section {
    display: block !important;
    display: flex !important;
    position: absolute !important;
    margin-left: 18px !important;
    padding: 0 36px 24px 0 !important;
    width: 100% !important;
    bottom: 0 !important;
    z-index: 99999 !important;
  }
  .mobile-done-join-form-cta-section {
    display: block !important;
    //display: flex !important;
    position: absolute !important;
    margin-left: 18px !important;
    padding: 0 36px 24px 0 !important;
    width: 100% !important;
    bottom: 0 !important;
    z-index: 99999 !important;
  }
  .mobile-join-artworkTop {
    display: block !important;
    position: absolute !important;
    top: 0 !important;
    z-index: 102 !important;
    right: 0 !important;
  }
  .join-artworkTop {
    display: none !important;
  }
}

//end 540px

@media (min-width: 480px) and (max-width: 1100px) {
}

@media (max-device-width: 1366px) {
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1600px) {
  .filter-color-first-wrapper {
    left: 1309px !important;
  }
}
