//@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

$bg-color: #eaedf7;
$search-ng: #fbfbfb;
$white: rgba(255, 255, 255, 1);
$line: linear-gradient(3deg, rgba(211, 99, 61, 1) 0%, rgba(84, 51, 183, 1) 35%);
$outline-gradient: linear-gradient(181.28deg, #5433b7 -1.31%, #fec4b4 98.9%);

$primary-color: rgba(84, 51, 183, 1);
$primary-color-100: rgba(84, 51, 183, 0.5);
$primary-color-200: rgba(84, 51, 183, 0.6);
$primary-color2: #3f2591;
$primary-color-900: #5e3ebb;
$primary-color3: #5433b7;

$primary-color-1: #fec4b4;
$primary-color-opacity50: rgba(254, 196, 180, 0.4);

$second-color-1: #fd8061;
$second-color-1-opacity50: rgba(253, 128, 97, 0.32);

$child-color-2: #6faa4f;
$child-color-2-10: rgba(111, 170, 79, 0.17);
$child-color-2-opacity50: rgba(111, 170, 79, 0.12);
$child-color-2-opacity100: rgba(111, 170, 79, 0.3);

$child-color-3: #6e9d4b;

$child-color-4: #5a3584;
$child-color-4-opacity50: rgba(90, 53, 132, 0.32);
$child-color-5: #6e9d4b;
$child-color-5-opacity50: rgba(110, 157, 75, 0.14);
$child-color-6: #d3633d;
$child-color-6-opacity10: rgba(211, 99, 61, 0.12);
$child-color-7: #c78757;
$child-color-8: #7c5f2b;
$child-color-9: #e5a133;
$child-color-10: #7c5f2b;
$child-color-9-opacity50: rgba(229, 161, 51, 0.1);
$child-color-opacity50: rgba(229, 161, 51, 0.22);
$child-child-color-10: #3b4f94;
$child-child-color-11: #2e396d;

$primary-color-opacity: rgba(0, 128, 199, 0.1);
$child-color-1-opacity-10: rgba(28, 131, 172, 0.1);
$child-color-1-opacity-100: rgba(28, 131, 172, 1);
$primary-color-opacity: rgba(0, 129, 205, 0.1);
$secondary-color-opacity: rgba(236, 0, 140, 0.1);
$child-color-1-opacity: rgba(28, 131, 172, 0.1);
$child-color-2-opacity: rgba(116, 103, 186, 0.1);
$child-color-3-opacity: rgba(79, 70, 127, 0.1);

$border-radius-sm: 12px;
$border-radius-md: 16px;
$border-radius-lg: 18px;

$primary-color-100: #f9f6ff;
$primary-color-200: #ede8fe;

$primary-color-1-100: #feeeea;

$second-color-1-1: #fad633;

$second-color-2: #2f9977;
$second-color-2-1: #fbe291;

$second-color-3: #2258ac;
$primary-color-3-1: #5cf3fd;

$boarder-1: #c9cddb;
$boarder-3: #e8e8e8;
$boarder-2: #c9cddb;
$boarder-light: #d6d7d8;

$font-primary-color: #131c3a;
$font-second-color: #667092;
$font-child-color-1: #667092;
$font-child-color-2: #667092;
$font-child-color-3: #6faa4f;
$font-child-color-4: #6e9d4b;
$font-child-color-5: #5a3584;
$font-child-color-6: #6e9d4b;
$font-child-color-7: #d3633d;
$font-child-color-8: #c78757;
$font-child-color-9: #7c5f2b;
$font-child-color-10: #e5a133;
$font-child-color-11: #3b4f94;
$font-child-color-12: #2e396d;
$second-font: #667092;

//----- boarder radius
$border-radius10: 10px;
$border-radius15: 15px;
$border-radius20: 20px;

$gray-100: #f0f0f0;
$gray-200: #efefef;
$gray-300: #cacedb;
$gray-400: #f2f2f2;
$gray-500: #b5b8c2;
$gray-600: #afafb9;
$gray-700: #9a9aa9;
$gray-800: #818181;
$gray-900: #2a2a2a;

$stock: #eeedf1;

$black: #000000;
$icon-default: #818181;
$separate-line: #f1efef;

$active-color: #151515;

$primary-font: "gothambook", sans-serif;

$gotham300: "gothamlight", sans-serif;
$gotham400: "gothambook", sans-serif;
$gotham500: "gothambold", sans-serif;
$gotham900: "gothamblack", sans-serif;
//$primary-font: 'Nunito Sans', sans-serif;

$font-light: "gothamlight", sans-serif;
$font-regular: "gothambook", sans-serif;
$font-medium: "gothambold", sans-serif;
$font-bold: "gothamblack", sans-serif;

$archivo300: "Archivo", sans-serif;
$archivo400: "Archivo", sans-serif;
$archivo500: "Archivo", sans-serif;
$archivo600: "Archivo", sans-serif;

$font-primary-regular: "Archivo", sans-serif;
$font-primary-medium: "Archivo", sans-serif;
$font-primary-bold: "Archivo", sans-serif;
$font-primary-black: "Archivo", sans-serif;

$lato100: "Lato-Thin", sans-serif;
$lato300: "Lato-Light", sans-serif;
$lato400: "Lato-Regular", sans-serif;
$lato700: "Lato-Bold", sans-serif;
$lato900: "Lato-Black", sans-serif;

$font-second-thin: "Lato-Thin", sans-serif;
$font-second-light: "Lato-Light", sans-serif;
$font-second-regular: "Lato-Regular", sans-serif;
$font-second-bold: "Lato-Bold", sans-serif;
$font-second-black: "Lato-Black", sans-serif;

$red: #dd4343;
$green: #12b754;

$hSbc-bg: #ffe2e5;
$samp-bg: #ffe9da;
$boc: #ffca08;

$modal-bg: rgba(1, 1, 6, 0.8);
