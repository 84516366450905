/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on May 31, 2021 */

// 1. Gotham

@font-face {
  font-family: "gothamblack";
  src: url("gotham-black-webfont.woff") format("woff2"),
    url("gotham-black-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "gothamlight";
  src: url("gotham-light-webfont.woff") format("woff2"),
    url("gotham-light-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "gothambold";
  src: url("gotham-bold-webfont.woff") format("woff2"),
    url("gotham-bold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "gothambook";
  src: url("gothambook-webfont.woff") format("woff2"),
    url("gothambook-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "gothammedium";
  src: url("gothammedium-webfont.woff") format("woff2"),
    url("gothammedium-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

//.2 Archivo

@font-face {
  font-family: "Archivo-Regular";
  src: url("Archivo-Regular.ttf") format("ttf"),
    url("Archivo-Regular.ttf") format("ttf");
  font-weight: 300;
}

@font-face {
  font-family: "Archivo-Medium";
  src: url("Archivo-Medium.ttf") format("ttf"),
    url("Archivo-Medium.ttf") format("ttf");
  font-weight: 400;
}

@font-face {
  font-family: "Archivo-Bold";
  src: url("Archivo-Bold.ttf") format("ttf"),
    url("Archivo-Bold.ttf") format("ttf");
  font-weight: 500;
}

@font-face {
  font-family: "Archivo-Black";
  src: url("Archivo-Black.ttf") format("ttf"),
    url("Archivo-Black.ttf") format("ttf");
  font-weight: 600;
}

@font-face {
  font-family: "Lato-Thin", sans-serif;
  font-weight: 100;
  src: url("Lato-Thin.ttf") format("ttf"), url("Lato-Thin.ttf") format("ttf");
  font-style: normal;
}

@font-face {
  font-family: "Lato-Light", sans-serif;
  src: url("Lato-Light.ttf") format("ttf"), url("Lato-Light.ttf") format("ttf");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Lato-Regular", sans-serif;
  src: url("Lato-Regular.ttf") format("ttf"),
    url("Lato-Regular.ttf") format("ttf");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Lato-Bold", sans-serif;
  src: url("Lato-Bold.ttf") format("ttf"), url("Lato-Bold.ttf") format("ttf");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Lato-Black", sans-serif;
  src: url("Lato-Black.ttf") format("ttf"), url("Lato-Black.ttf") format("ttf");
  font-weight: 900;
  font-style: normal;
}
