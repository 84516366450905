@import "../../assets/stylesheets/modules/variable.scss";

.modal-main-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 100;
}

.modal-body-wrapper {
  width: 100%;
  height: 100%;
  background: $white;
}

.m-header-wrapper {
  padding: 24px;
  border-bottom: 1px solid $gray-300;
  .m-h-title {
    font-family: $gotham400;
    font-size: 16px;
    color: $font-second-color;
  }

  .m-h-close {
    font-family: $gotham400;
    color: $gray-500;
    cursor: pointer;

    &:hover {
      color: $red;
    }
  }
}


.m-content-body{
  padding: 24px;
}
