@import "../../assets/stylesheets/modules/variable";
@import "../../assets/stylesheets/modules/mixin";

.modal-bg-wrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  overflow-y: auto;
  overflow-x: hidden;

  .landing-bg {
    position: relative;

    img {
      position: absolute;
      left: 0;
      top: 0;
    }
  }

  &.top-form {
    z-index: 1000;
  }
}

.join-form-content-wrapper {
  width: auto;
  min-height: 600px;
  background: $white;
  margin: 1rem auto;
  position: relative;
  overflow: hidden;
  border-radius: 25px !important;

  .form-inner-wrapper {
    position: relative;
    // width: 400px;
    height: fit-content;
    margin: 2rem auto;
    padding: 0 !important;
    z-index: 100;
  }

  .content-header-wrp {
    text-align: center;

    .sub-text {
      font-size: 12px;
      line-height: 5px;
      color: $font-second-color;
    }
  }

  .modal-footer-wrapper {
    height: 65px;
    border-top: 1px solid $boarder-1;
    align-items: center;
  }
}

.interesting-wrapper {
  margin-top: 2rem;
}

.setup-account-wrapper {
  margin-top: 1rem;
}

.close-modal-wrapper {
  position: absolute;
  right: 24px;
  top: 24px;
  z-index: 100;
}

.modal-header-wrapper {
  font-family: $primary-font;

  .header {
    font-size: 25px;
    font-weight: 500;
    color: $font-primary-color;
  }

  .small-text {
    font-size: 12px;
    font-weight: 400;
    color: $primary-color;
  }
}

.data-form-wrapper {
  margin-top: 32px;

  .join-form-page-title {
    @include font-light();
    font-size: 12px;
    color: $font-second-color !important;
    font-weight: 400;
    padding-bottom: 0.2rem;
  }
}

.sign-in-button-wrapper {
  margin-top: 32px !important;
}

.forgot-password-wrap {
  font-family: $primary-font;
  cursor: pointer;
  font-size: 10px;
  display: flex;
  justify-content: flex-end;
  padding-top: 12px;

  &:hover {
    color: $primary-color;
  }
}

.form-wizard-wrapper {
  display: flex;
  margin: 0 auto;
  //@include itemCenter();
  //align-items: flex-start;

  .timeline-wrapper {
    width: 290px;
    z-index: 10;
  }

  .wizard-form-wrapper {
    width: 400px;
  }
}

.BRattachment-wrapper {
  padding: 12px 16px 12px 16px;
  font-size: 12px;
  border-radius: 10px;
  width: fit-content;
  @include itemCenter();
  cursor: pointer;
  z-index: 10;

  &.sm {
    padding: 6px 10px 6px 10px;
  }

  .uploadBtn {
    padding: 12px 16px 12px 16px;
    z-index: 100;
  }

  .text {
    padding: 0;
    line-height: 22px;
    font-family: $gotham400;
    margin-left: 12px;
  }

  &.light {
    cursor: pointer;
    background: $white;
    color: $primary-color;
    border: 1px solid $gray-100;

    &:hover,
    &:focus {
      background: $primary-color;
      color: $white;
      border: 1px solid transparent;
    }

    &.Attached {
      border: 1px solid $primary-color-1;
      cursor: none;
    }

    &.error {
      border: 1px solid $red;
    }
  }
}

.mobile-no-found {
  background: $primary-color-100;
  font-size: 12px;
  border-radius: 10px;
  padding: 12px;
  margin-top: 1rem;
  color: $primary-color;
}

.login-url-wrap {
  width: 100%;
  @include font-regular();
  text-align: center;
  font-size: 12px;
  align-items: center;
  justify-content: center;
  margin-top: 24px;
  z-index: 99;
  .link {
    @include font-medium();
    cursor: pointer !important;
    color: $primary-color;
    z-index: 99;
    &:hover {
      color: $child-color-2;
      text-decoration: underline;
    }
  }
}

.login-form-OTP-message {
  text-align: center;
  @include font-regular();
  font-size: 12px;
  color: $font-primary-color;
  margin-top: 1.5rem;
}
.re-send {
  @include font-medium();
  color: $font-primary-color;
  font-size: 12px;
  text-align: center;

  &.link {
    cursor: pointer;
    text-decoration: underline;
    &:hover {
      color: $child-color-5;
      text-decoration: underline;
    }
  }
}

//----------- pre loader
.join-form-main-wrapper {
}
.loading-mask {
  background-color: rgba(255, 255, 255, 0.9);
  z-index: 100;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}

.filter-bg-1 {
  position: absolute;
  top: 0;
  right: 10%;
  margin-left: 103px;
  margin-top: -100px;
  z-index: 0;
}
.filter-bg-2 {
  position: absolute;
  left: 10%;
  bottom: 0;
  z-index: 0;
}

.mobile-join-artworkTop {
  display: none !important;
}
.mobile-login-url-wrap {
  display: none !important;
  font-family: $gotham400 !important;
  text-align: center;
  font-size: 12px;
  margin-top: 48px;

  .link {
    font-family: $gotham500;
    font-weight: normal !important;
    cursor: pointer !important;
    color: $primary-color !important;
    z-index: 100;

    &:hover {
      color: $second-color-1 !important;
    }
  }
}
.mobile-join-form-cta-section {
  display: none !important;
}
.mobile-done-join-form-cta-section {
  display: none !important;
}
