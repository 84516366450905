@import "variable";
@import "../main";
@import "../../../assets/font/font-face.scss";

//font family
@mixin font-light() {
  font-family: $font-light;
  font-weight: 300;
}

@mixin font-regular() {
  font-family: $font-regular;
}

@mixin font-medium() {
  font-family: $font-medium;
}

@mixin font-bold() {
  font-family: $font-bold;
}

@mixin font-primary-regular() {
  font-family: $font-primary-regular;
}

@mixin font-primary-medium() {
  font-family: $font-primary-medium;
}

@mixin font-primary-bold() {
  font-family: $font-primary-bold;
}

@mixin font-primary-black() {
  font-family: $font-primary-black;
}

@mixin font-second-thin() {
  font-family: $font-second-thin;
}
@mixin font-second-light() {
  font-family: $font-second-light;
}

@mixin font-second-regular() {
  font-family: $font-second-regular;
}

@mixin font-second-bold() {
  font-family: $font-second-bold;
}

@mixin font-second-black() {
  font-family: $font-second-black;
}

@mixin font-style($family, $size, $weight, $color) {
  font-family: $family;
  font-size: $size;
  font-weight: $weight;
  color: $color;
}

@mixin centerDiv($width, $height) {
  width: $width;
  height: $height;
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin itemCenter() {
  display: flex;
  justify-content: center;
  align-items: center;
}
@mixin alignCenter() {
  display: flex;
  justify-content: center;
}
@mixin objectFitCover() {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@mixin menu-icon-shadow() {
  -webkit-box-shadow: -5px 17px 48px -12px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: -5px 17px 48px -12px rgba(0, 0, 0, 0.2);
  box-shadow: -5px 17px 48px -12px rgba(0, 0, 0, 0.2);
}

@mixin align-item-center() {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin create-div-with-position($width, $height, $position) {
  width: $width;
  height: $height;
  position: $position;
}

@mixin animation-duration($time) {
  -moz-animation-duration: $time;
  -webkit-animation-duration: $time;
  animation-duration: $time;
}

//@mixin animation($name,$time,) {
//  -moz-animation: buble 2s linear 1s infinite;
//}

@mixin wave() {
  position: absolute;
  background: $primary-color;
  margin-left: 0;
  margin-top: 0;
  z-index: -100;
  width: 32px;
  height: 32px;
  content: "";
  border-radius: 50%;
  -webkit-backface-visibility: hidden;
}
