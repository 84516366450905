@import "../../assets/stylesheets/modules/variable.scss";

html,
body {
  font-family: $primary-font;
  //overflow: hidden;
  position: relative;
  background: #f0f2f4;
  letter-spacing: 0 !important;
  font-weight: normal !important;
  overflow-x: hidden;
  overflow-y: auto;
  scroll-behavior: smooth;
}


h1 {
  font-family: $gotham500;
  font-size: 20px;
}

h5 {
  font-size: 12px;
  color: $second-font;
}

small {
  font-size: 11px;
  color: $gray-500;
}

.form-control::-webkit-input-placeholder, /* Edge */
input::-webkit-input-placeholder, /* Edge */
Select::-webkit-input-placeholder, /* Edge */
select::-webkit-input-placeholder, /* Edge */
textarea::-webkit-input-placeholder {
  /* Edge */
  font-family: $primary-font;
  //font-weight: 300;
  color: $gray-500 !important;
  font-size: 12px;
}

.form-control:-ms-input-placeholder, /* Internet Explorer 10-11 */
input:-ms-input-placeholder, /* Internet Explorer 10-11 */
Select:-ms-input-placeholder, /* Internet Explorer 10-11 */
select:-ms-input-placeholder, /* Internet Explorer 10-11 */
textarea:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  font-family: $primary-font;
  //font-weight: 300;
  color: $gray-500 !important;
  font-size: 12px;
}

.form-control::placeholder,
input::placeholder,
Select::placeholder,
select::placeholder,
textarea::placeholder {
  font-family: $primary-font;
  //font-weight: 400;
  color: $gray-500 !important;
  font-size: 12px;
}

.mt-24 {
  margin-top: 24px;
}

.mt-16 {
  margin-top: 16px;
}

$notification-border: 10px;

.notification__item--default {
  background-color: $child-child-color-10;
  border-left: 8px solid #0562c7;
}

.notification__item--default .notification__timer {
  background-color: #007bff;
}

.notification__item--default .notification__timer-filler {
  background-color: #fff;
}

.notification__item--default .notification__close {
  background-color: #007bff;
}

.notification__item--success {
  background-color: $child-color-5;
  border-left: 8px solid #1f8838;
}

.notification__item--success .notification__timer {
  background-color: #28a745;
}

.notification__item--success .notification__timer-filler {
  background-color: #fff;
}

.notification__item--success .notification__close {
  background-color: #28a745;
}

.notification__item--danger {
  background-color: $second-color-1;
  border-left: 2px solid #bd1120 !important;
}

.notification__item--danger .notification__timer {
  background-color: #dc3545;
}

.notification__item--danger .notification__timer-filler {
  background-color: #fff;
}

.notification__item--danger .notification__close {
  background-color: #dc3545;
}

.notification__item--info {
  background-color: $child-color-8;
  border-left: 8px solid $child-color-8;
}

.notification__item--info .notification__timer {
  background-color: #17a2b8;
}

.notification__item--info .notification__timer-filler {
  background-color: #fff;
}

.notification__item--info .notification__close {
  background-color: #17a2b8;
}

.notification__item--warning {
  background-color: $child-color-9;
  border-left: 8px solid $child-color-9;
}

.notification__item--warning .notification__timer {
  background-color: #eab000;
}

.notification__item--warning .notification__timer-filler {
  background-color: #fff;
}

.notification__item--warning .notification__close {
  background-color: #eab000;
}

.notification__item--awesome {
  background-color: #685dc3;
  border-left: 8px solid #4c3fb1;
}

.notification__item--awesome .notification__timer {
  background-color: #685dc3;
}

.notification__item--awesome .notification__timer-filler {
  background-color: #fff;
}

.notification__item--awesome .notification__close {
  background-color: #685dc3;
}

@keyframes timer {
  0% {
    width: 100%;
  }
  to {
    width: 0;
  }
}

.notifications-component {
  position: fixed;
  z-index: 9000;
  pointer-events: none;
  width: 100%;
  height: 100%;
}

.notification__item {
  display: flex;
  position: relative;
  border-radius: 3px;
  margin-bottom: 15px;
  box-shadow: 1px 3px 4px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.notification-container--bottom-full .notification__item,
.notification-container--top-full .notification__item {
  margin-bottom: 0;
  border-radius: 0;
}

.notification__timer {
  width: 100%;
  margin-top: 10px;
}

.notification__timer,
.notification__timer-filler {
  height: 3px;
  border-radius: 5px;
}

.notification__title {
  color: #fff;
  font-family: $gotham500;
  font-size: 14px;
  margin-top: 5px;
  margin-bottom: 5px;
}

.notification__message {
  color: #fff;
  font-family: $gotham400;
  max-width: calc(100% - 15px);
  font-size: 14px;
  line-height: 150%;
  word-wrap: break-word;
  margin-bottom: 0;
  margin-top: 0;
}

.notification__content {
  padding: 8px 15px;
  display: inline-block;
  width: 100%;
}

.notification__close {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  display: inline-block;
  position: absolute;
  right: 10px;
  top: 10px;
}

.notification__close:after {
  content: "\D7";
  position: absolute;
  transform: translate(-50%, -50%);
  color: #fff;
  font-size: 12px;
  left: 50%;
  top: 50%;
}

.notification-container--mobile-bottom .notification,
.notification-container--mobile-bottom .notification__item,
.notification-container--mobile-top .notification,
.notification-container--mobile-top .notification__item {
  max-width: 100%;
  width: 100%;
}

.notification-container--bottom-right .notification,
.notification-container--top-right .notification {
  margin-left: auto;
}

.notification-container--bottom-left .notification,
.notification-container--top-left .notification {
  margin-right: auto;
}

.notification-container--mobile-bottom .notification,
.notification-container--mobile-top .notification {
  margin-left: auto;
  margin-right: auto;
}

.lbl-mb5 {
  margin-bottom: 5px;
}

//MODAL
.coo__modal-main-wrapper {
  position: fixed;
  background: $modal-bg;
  width: 100%;
  height: 100%;
  z-index: 9999;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  overflow-y: auto;
  overflow-x: hidden;
  &.show-me {
    display: block;
  }

  &.hide-me {
    display: none;
  }

  .coo_modal-body-content {
    will-change: transform;
    background: $white;
    min-height: 300px;
    position: relative;
    margin: 1rem auto 1rem auto;
    border-radius: 15px;
    overflow: hidden;
    will-change: auto;

    &.fix-400 {
      width: 400px;
    }

    &.fix-500 {
      width: 500px;
    }

    &.fix-550 {
      width: 550px;
    }
    &.fix-536 {
      width: 536px;
    }
  }
}

.text-white {
  color: $white;
}

.verification-message {
  font-family: $primary-font;
  font-size: 12px;
}

.rnc__notification-container--bottom-left {
  bottom: 70px !important;
}

.rnc__notification-item--success {
  background-color: $font-child-color-4;
  border-left: 8px solid $font-child-color-6;
  .rnc__notification-timer-filler {
    background-color: $child-color-9;
  }
  .rnc__notification-timer {
    background-color: $child-color-8;
  }
}
.rnc__notification-title {
  font-family: $gotham300;
  font-size: 11px;
}
.rnc__notification-message {
  font-family: $gotham400;
  font-size: 12px;
}
