@import "../../../../assets/stylesheets/modules/variable";
@import "../../../../assets/stylesheets/modules/mixin";

.cco_recent-view-offer-wrapper-mobile {
  background-color: white;
  //border-radius: 17px;
  padding: 12px 8px;
  position: fixed;
  top: 5.3%;
  left: 0;
  //margin-top: 24px;
  margin-bottom: 24px;
  gap: 14px;
  z-index: 1005;

  .cco_rvowm__title {
    padding-left: 12px;
    // padding-bottom: 12px;
  }
  .cco_rvowm__cards-wrp {
    gap: 8px;
    overflow: hidden;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(73px, 1fr));
    max-height: 120px;
    padding: 12px 8px;
    &.more {
      max-height: 100%;
    }
    .rvowm__cw-cards {
      gap: 16px;
    }
    .cco_rvowm__cw-slider {
      display: flex !important;
      flex-direction: row !important;
      grid-gap: 12px !important;
      gap: 12px !important;
      //padding-left: 0 !important;
    }
  }
}

.slick-dots li {
  display: none !important;
}
.slick-slider {
  padding: 0;
}

.bsc_main-wrapper-shimmer {
  width: 100%;
  max-height: 92px;
  border: 1px solid $boarder-1;
  padding: 8px;
  border-radius: 14px;
  align-items: center;
  cursor: pointer;
  overflow: hidden;
  z-index: 99999;
  background-color: $white;
  .bsc_mws-bank_logo {
    width: 47px;
    height: 47px;
    margin-bottom: 4px;
    border-radius: 100px;
    overflow: hidden;
  }
  .bsc_mws-bank_Name {
    width: 50px;
    height: 12px;
    margin-bottom: 4px;
  }
}

.shimmerBG {
  animation-duration: 2.2s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: shimmer;
  animation-timing-function: linear;
  background: #ddd;
  background: linear-gradient(to right, #f6f6f6 8%, #f0f0f0 18%, #f6f6f6 33%);
  background-size: 1200px 100%;
}
